<template>
  <section class="fee-payment">
    <div class="fee-head">
      <mobile-screen-header title="Fee Payment"></mobile-screen-header>

      <div class="container" @click="sheet = true">
        <!-- :title="invoice_data !== null ?  + total_amount : 'Click To Select Student'" -->
        <mobile-screen-box
          :title="total_amount || 'Filter Student'"
          :info="
            selectedStudent.full_name &&
            selectedStudent.grade &&
            selectedStudent.section
              ? selectedStudent.full_name +
                ' / ' +
                selectedStudent.grade +
                ' / ' +
                selectedStudent.section
              : 'Please click here to'
          "
        ></mobile-screen-box>
      </div>

      <div class="container" v-if="invoice_data !== null">
        <mobile-screen-transaction
          title="Transaction Summary"
          :info="'Total ' + total_amount + ' / ' + 'Paid ' + total_paid"
          :percentage="calculated_percentage + '%'"
        >
        </mobile-screen-transaction>
        <div class="invoice-list">
          <h1>Invoice Due List.</h1>

          <mobile-screen-invoice-list
            v-on:setInvoiceStatus="setInvoiceStatus"
            title="Invoice Due List."
            info="All"
            info2="Dues"
            info3="Partial Paid"
            info4="Full Paid"
          ></mobile-screen-invoice-list>
        </div>
        <template v-for="(inv_data, index) in invoice_data">
          <mobile-screen-transaction-month
            :key="index"
            :uniqueValue="index"
            v-if="
              invoice_status == 4
                ? inv_data.invoice_status == 0 ||
                  inv_data.invoice_status == 1 ||
                  inv_data.invoice_status == 3
                : invoice_status == 0
                ? inv_data.invoice_status == 0 || inv_data.invoice_status == 3
                : invoice_status == 1
                ? inv_data.invoice_status == 1
                : inv_data.invoice_status == 3
            "
            :title="inv_data.monthname"
            :info="
              inv_data.details.length +
                ' Heads,' +
                ' Total Due: ' +
                inv_data.total_due_amount.numberFormat()
            "
            :details="inv_data.details"
            :penalty="inv_data.penalty"
            :cash="inv_data.bill_amount.currency()"
            :color_code_status="inv_data.invoice_status"
          >
          </mobile-screen-transaction-month>
        </template>
          <not-found v-if="check_data === undefined" 
          :width="200"
          :title="'No Invoices'"
          :text="'No invoice were found for the selected tabs'"></not-found>

        <mobile-screen-footer-box
          v-if="!hide_footer"
          title="Message"
          title2="Pay Now"
        ></mobile-screen-footer-box>
      </div>
      <div v-else>
        <not-found
          :width="400"
          :title="'Fee Information not Available'"
          :text="'Please select the student  to fetch the Fee Information'"
        ></not-found>
      </div>
    </div>
    <!-- ========= NAVIGATION DRAWER =============== -->
    <v-navigation-drawer
      v-model="sheet"
      absolute
      bottom
      temporary
      right
      width="700"
    >
      <div class="filter-container">
        <div class="white--text filter-header">
          <h1>Fee Payment</h1>
          <p>Select Student / Exam to fetch payment.</p>
        </div>

        <div class="filter-body">
          <div class="filter-content-holder">
            <guardian-children-list
              :result="true"
              v-on:setArrayKey="setArrayKey"
              :reset_data="reset_data"
            ></guardian-children-list>
          </div>

          <v-divider></v-divider>
          <br />
        </div>

        <v-card-actions class="filter-footer-action">
          <v-layout row wrap style="margin-bottom: 20px">
            <v-flex xs3>
              <v-btn
                elevation="0"
                color="default"
                large
                block
                @click="sheet = !sheet,resetData()"
              >
                Close
              </v-btn>
            </v-flex>
            <v-flex xs9 pl-2>
              <v-btn
                elevation="0"
                color="primary"
                block
                large
                @click="getStudentInvoice()"
                :disabled="disable_filter"
              >
                Filter
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </div>
    </v-navigation-drawer>
    <!-- ========= END NAVIGATION DRAWER =========== -->
  </section>
</template>
<script>
export default {
  data: () => ({
    hide_footer: true,
    sheet: false,
    selectedStudent: {
      enroll_code: "",
      section_id: "",
      also_paid: true,
      enroll_id: "",
      full_name: "",
      grade: "",
      section: "",
    },
    invoice_data: null,
    bill_amount: "",
    total_paid_amount: "",
    invoice_status: 0,
    check_data:'',
    disable_filter:true,
    reset_data:false,
  }),
  mounted() {
    // this.getStudentInvoice();
  },
  computed: {
    calculated_percentage: function() {
      if (this.invoice_data !== null) {
        let total_sum = 0;
        let paid_sum = 0;
        this.invoice_data.map((res) => {
          total_sum += res.bill_amount;
          paid_sum += res.total_paid_amount;
        });
        return Math.round((paid_sum / total_sum) * 100);
      } else {
        return "";
      }
    },

    total_amount: function() {
      if (this.invoice_data !== null) {
        let total_sum = 0;
        this.invoice_data.map((res) => {
          total_sum += res.bill_amount;
        });
        return total_sum.currency();
      } else {
        return "";
      }
    },
    total_paid: function() {
      if (this.invoice_data !== null) {
        let total_paid_sum = 0;
        this.invoice_data.map((res) => {
          total_paid_sum += res.total_paid_amount;
        });
        return total_paid_sum.currency();
      } else {
        return "";
      }
    },
  },
  methods: {
    resetData(){
      if(this.invoice_data){
        this.selectedStudent.enroll_code="";
        this.selectedStudent.section_id="";
        this.selectedStudent.enroll_id="";
        this.selectedStudent.full_name="";
        this.selectedStudent.grade="";
        this.selectedStudent.section="";
        this.selectedStudent.also_paid=true;
        this.reset_data=true;
        this.invoice_data=null;
        this.disable_filter=true;
      }

    },
    getStudentInvoice() {
      this.invoice_data = null;
      let url =
        "/api/invoice/student?enrollId=" +
        this.selectedStudent.enroll_id +
        "&sectionId=" +
        this.selectedStudent.section_id +
        "&enrollCode=" +
        this.selectedStudent.enroll_code +
        "&alsoPaid=" +
        this.selectedStudent.also_paid;
      this.$rest
        .get(url)
        .then(({ data }) => {
          if(data.data.length > 0){
            this.invoice_data = data.data;
          }else{
            this.invoice_data = null
          }
          this.reset_data = false;
          this.sheet = false;
          // this.total_paid_amount = data.data[0].total_paid_amount;
          // this.bill_amount = data.data[0].bill_amount;
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: "No record found.",
            status: "error",
          });
          this.sheet = false;
        });
    },
    setArrayKey(value) {
      this.disable_filter = false;
      if(value){
        this.selectedStudent.full_name = value.full_name;
        this.selectedStudent.enroll_code = value.academic.enroll_code;
        this.selectedStudent.enroll_id = value.academic.enroll_id;
        this.selectedStudent.section_id = value.academic.section_id;
        this.selectedStudent.section = value.academic.section;
        this.selectedStudent.grade = value.academic.grade;
      }
    },
    setInvoiceStatus(v) {
      this.check_data = '';
      if(v != 4){
        this.check_data = this.invoice_data.find((element, index) => { 
          return element.invoice_status == v;         
         } )
      }
      this.invoice_status = v;

    },
  },
};
</script>
